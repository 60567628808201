<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form ref="form" :model="form" :rules="rules" label-width="170px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="姓名" prop="personName">
            <el-input v-model="form.personName" placeholder="姓名" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别" prop="personSex">
            <el-select v-model="form.personSex" placeholder="请选择性别" style="width:100%">
              <el-option v-for="item in sexList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="联系方式" prop="personTel">
            <el-input v-model="form.personTel" placeholder="请输入联系方式" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="出生日期" prop="personBithDay">
            <el-date-picker
              v-model="form.personBithDay"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="请选择出生日期"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="身份证号" prop="personIdNum">
            <el-input v-model="form.personIdNum" placeholder="请输入身份证号" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="紧急联系人姓名" prop="emePersonName">
            <el-input v-model="form.emePersonName" placeholder="请输入紧急联系人姓名" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="紧急联系人于本人关系" prop="emePersonShip">
            <el-input v-model="form.emePersonShip" placeholder="请输入紧急联系人于本人关系" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="紧急联系人方式" prop="emePersonTel">
            <el-input v-model="form.emePersonTel" placeholder="请输入紧急联系人方式" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="身份特征" prop="characters">
            <el-input v-model="form.characters" placeholder="请输入身份特征" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="市" prop="city">
            <el-input v-model="form.city" placeholder="请输入市" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="区" prop="area">
            <el-input v-model="form.area" placeholder="请输入区" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="街道" prop="street">
            <el-input v-model="form.street" placeholder="请输入街道" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="机构地址" prop="address">
            <el-input v-model="form.address" type="textarea" :rows="3" placeholder="请输入机构地址" auto-complete="off" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="改造项目名称" prop="chgProejctName">
            <el-input v-model="form.chgProejctName" type="textarea" :rows="3" placeholder="请输入改造项目名称" auto-complete="off" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="24">
          <el-form-item label="改造图片" prop="chgImgs">
            <upload-img :limit="6" :upload-lists="imgsList" @uploadChange="uploadSuccess" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
export default {
  name: 'AddOrEdit',
  components: {
    UploadImg
  },
  data() {
    return {
      sexList: [
        {
          value: '男',
          label: '男'
        },
        {
          value: '女',
          label: '女'
        }
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() // 选当前时间之前的时间
        }
      },
      imgsList: [],
      titleName: '',
      photoUrl: '',
      form: {},
      rules: {
        personName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        personSex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        personTel: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$/, message: '请检查联系方式格式' }
        ],
        personBithDay: [{ required: true, message: '请选择出生日期', trigger: 'blur' }],
        personIdNum: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请检查身份证号格式' }
        ],
        emePersonName: [{ required: true, message: '请输入紧急联系人姓名', trigger: 'blur' }],
        emePersonShip: [{ required: true, message: '请输入紧急联系人于本人关系', trigger: 'blur' }],
        emePersonTel: [
          { required: true, message: '请输入紧急联系人方式', trigger: 'blur' },
          { pattern: /^(1[3456789]\d{9})$/, message: '请检查紧急联系人方式格式' }
        ],
        characters: [{ required: true, message: '请输入身份特征', trigger: 'blur' }],
        chgProejctName: [{ required: true, message: '请输入改造项目名称', trigger: 'blur' }],
        city: [{ required: true, message: '请输入市', trigger: 'blur' }],
        area: [{ required: true, message: '请输入区', trigger: 'blur' }],
        street: [{ required: true, message: '请输入街道', trigger: 'blur' }],
        address: [{ required: true, message: '请输入机构地址', trigger: 'blur' }]
      }
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    }
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['downloadFile', 'addOrSaveChgPerson', 'detailChgPerson']),
    getCurrentRow(row) {
      this.form.serviceSiteId = row.id
    },
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    initView() {
      this.getDetail()
    },
    uploadSuccess(data) {
      this.form.chgImgs = data.join(',')
    },
    async getDetail() {
      if (this.routeType == 'add') {
        this.titleName = '添加人员信息'
      } else {
        this.titleName = '修改人员信息'
        this.itemData = JSON.parse(this.$route.query.item)
        // 这里调用请求编辑时的详情接口渲染form表单数据

        const params = {
          id: this.itemData.id
        }
        let data = null
        const server = 'detailChgPerson'
        await this[server](params).then((res) => {
          if (res.code == 200) {
            data = res.data
            this.form = {
              id: data.id,
              personName: data.personName,
              personSex: data.personSex,
              personTel: data.personTel,
              personBithDay: data.personBithDay,
              personIdNum: data.personIdNum,
              emePersonName: data.emePersonName,
              emePersonShip: data.emePersonShip,
              emePersonTel: data.emePersonTel,
              characters: data.characters,
              chgProejctName: data.chgProejctName,
              chgImgs: data.chgImgs,
              city: data.city,
              area: data.area,
              street: data.street,
              address: data.address
            }
          }
        })
        if (this.form.chgImgs) {
          this.getFileDetail(this.form.chgImgs)
        }
      }
    },
    getFileDetail(ids) {
      ids = ids.split(',')
      ids.forEach((item) => {
        this.downloadFile(item).then(res => {
          this.imgsList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    submitEvent(formName) {
      if (!this.form.chgImgs) {
        this.$XModal.message({
          message: '请上传改造图片',
          status: 'warning'
        })
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            chgProejctId: null,
            ...this.form
          }
          const server = 'addOrSaveChgPerson'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success'
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .dialog-footer {
    text-align: center;
  }
}
</style>
